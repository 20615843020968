import React, {useState} from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import {CDN} from "../../helpers";
import { Fade } from 'react-slideshow-image';

import {OzContainer, OzRow, OzCol, OzButton} from "@ozwol/webui"; //SPANISHGREY

import Meta from '../../components/Meta';


const Wrapper = styled.div`
`;
const Line = styled.div`
  width: 60px;
  height: 5px;
  background-color: #00141f;
`;
const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  color: #00141f;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 991px){
    font-size: 25px;
  }
`;
const Date = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;
const Description = styled.div`

`;
const Image = styled.div`
  border: 1px solid #D0D0D0;
  border-radius: 17px;
  box-shadow: 0px 3px 3px #00000019;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  // width: ${props => props.width}px;
  max-width: 100%;

  & img{
    display: block;
    // width: ${props => props.width}px;
    max-width: 100%;
  }
`;

// const ImageCopyright = styled.div`
//   text-align: right;
//   margin-top: 3px;
//   font-size: 10px;
//   color: ${SPANISHGREY};

//   &>a {
//     color: inherit;
//   }
// `;


export default function Article() {
  const {lang} = useParams();
  const [face, setFace] = useState(true);

  return (
    <>
      <Meta
        title={(() => {switch(lang){
          case "it":
            return "Skin Beauty";
          default:
            return "Skin Beauty";
        }})()}
        description={(() => {switch(lang){
          case "it":
            return "Skin Beauty";
          default:
            return "Skin Beauty";
        }})()}
        canonicalSlug={(() => {switch(lang){
          case "it":
            return "blog/2-skin-beauty";
          default:
            return "blog/2-skin-beauty";
        }})()}
      />
      
      
      <Wrapper>
        <OzContainer size={"medium"}>
          <OzRow gap="25">
            <OzCol widthmd={12} order={2}>
              {face ? 
                <Image>
                  <Fade
                    duration={3000}
                    arrows={false}
                  >
                    <img
                      src={CDN+"website-assets/blog/2-skinbeauty-3.jpg"}
                      alt={"Face Beauty 1"}
                    />
                    <img
                      src={CDN+"website-assets/blog/2-skinbeauty-4.jpg"}
                      alt={"Face Beauty 2"}
                    />
                  </Fade>
                </Image>
              :
                <Image>
                  <Fade
                    duration={3000}
                    arrows={false}
                  >
                    <img
                      src={CDN+"website-assets/blog/2-skinbeauty-1.jpg"}
                      alt={"Skin Beauty 1"}
                    />
                    <img
                      src={CDN+"website-assets/blog/2-skinbeauty-2.jpg"}
                      alt={"Skin Beauty 2"}
                    />
                  </Fade>
                </Image>
              }
              <br/>
              <OzButton 
                variant="push-black"
                onClick={() => setFace(true)}
                pushed={face}
              >
                {(() => {switch(lang){
                  // case "it":
                  //   return "ColorSwatch: Generate Image Samples for Color Swatches";
                  default:
                    return "Face beauty";
                }})()}
              </OzButton>
              &nbsp;&nbsp;&nbsp;
              <OzButton 
                variant="push-black"
                onClick={() => setFace(false)}
                pushed={!face}
              >
                {(() => {switch(lang){
                  // case "it":
                  //   return "ColorSwatch: Generate Image Samples for Color Swatches";
                  default:
                    return "Skin beauty";
                }})()}
              </OzButton>
              <br/><br/>
              {/* <ImageCopyright>Photo: <a href="https://unsplash.com/it/@alirezadolati" target="_blank" rel="noreferrer">Alireza Dolati</a></ImageCopyright> */}
            </OzCol>
            <OzCol widthmd={12} order={1}>
              <Date>
                {(() => {switch(lang){
                  default:
                    return "Tuesday November 19, 2024";
                }})()}
              </Date>
              <Line/>
              <Title>
                {(() => {switch(lang){                 
                  default:
                    return "Skin blemish and imperfection removal powered by generative AI";
                }})()}
              </Title>
              <Description>
                {(() => {switch(lang){                  
                  default:
                    return <>
                      <p>
                        Discover how Ozwol's advanced generative AI transforms skin retouching into a seamless, efficient, and high-quality process.
                      </p>
                      <p>
                        Our Skin Beauty feature is designed to elevate your post-production by:
                        <ul>
                          <li>Removing skin imperfections with precision and natural aesthetics</li>
                          <li>Reducing wrinkles and rejuvenating facial appearance for a refreshed, youthful look</li>
                          <li>Ensuring the original beauty of the shot remains intact</li>
                          <li>Delivering photo-realistic results suitable for all skin tones</li>
                        </ul>
                        It's configurable to target either specific areas or entire sections of exposed skin.
                      </p>
                      <h3>
                        How the Process Works:
                      </h3>
                      <p>
                        1. <b>Script configuration:</b><br/>
                        Ozwol configures the process to target specific areas or entire sections of exposed skin, with adaptability to address the unique characteristics of every skin tone. Adjust settings for blemish removal and wrinkle reduction.
                      </p>  
                      <p>
                        2. <b>Image Upload:</b><br/>
                        You easily upload the images via API or Hotfolders. Our automated process integrates effortlessly into your existing workflows.
                      </p>
                      <p>
                        3. <b>Image Processing:</b><br/>
                        The Ozwol platform analyzes the uploaded images, detects areas of interest, and generates flawless skin tones and shades, reducing blemishes and wrinkles while preserving the natural contours and aesthetics of the face and/or body.<br/>
                        At the end of the process, you’ll receive high-quality outputs tailored to your specifications.
                      </p>
                      <p>
                        For more information on how to use Skin Beauty into your processes, please feel free to contact us.
                      </p>
                    </>;
                }})()}
              </Description>
              <br/>
              <Link to={"/"+lang+"/contact-us"}>
                <OzButton variant="chetwodeblue">
                  {(() => {switch(lang){
                    // case "it":
                    //   return "ColorSwatch: Generate Image Samples for Color Swatches";
                    default:
                      return "Contact us";
                  }})()}
                </OzButton>
              </Link>
            </OzCol>
          </OzRow>
        </OzContainer>
      </Wrapper>    
      
    </>
  )

}

