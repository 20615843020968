import React from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import {CDN} from "../../helpers";

import {SPANISHGREY, OzContainer, OzRow, OzCol, OzButton} from "@ozwol/webui";

import Meta from '../../components/Meta';


const Wrapper = styled.div`
`;
const Line = styled.div`
  width: 60px;
  height: 5px;
  background-color: #00141f;
`;
const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  color: #00141f;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 991px){
    font-size: 25px;
  }
`;
const Date = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;
const Description = styled.div`

`;
const Image = styled.div`
  border: 1px solid #D0D0D0;
  border-radius: 17px;
  box-shadow: 0px 3px 3px #00000019;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  // width: ${props => props.width}px;
  max-width: 100%;

  & img{
    display: block;
    // width: ${props => props.width}px;
    max-width: 100%;
  }
`;

const ImageCopyright = styled.div`
  text-align: right;
  margin-top: 3px;
  font-size: 10px;
  color: ${SPANISHGREY};

  &>a {
    color: inherit;
  }
`;


export default function Article() {
  const {lang} = useParams();

  return (
    <>
      <Meta
        title={(() => {switch(lang){
          case "it":
            return "Color Swatch";
          default:
            return "Color Swatch";
        }})()}
        description={(() => {switch(lang){
          case "it":
            return "Color Swatch";
          default:
            return "Color Swatch";
        }})()}
        canonicalSlug={(() => {switch(lang){
          case "it":
            return "blog/1-color-swatch";
          default:
            return "blog/1-color-swatch";
        }})()}
      />
      
      
      <Wrapper>
        <OzContainer size={"medium"}>
          <OzRow gap="25">
            <OzCol widthmd={12} order={2}>
              <Image>
                <img
                  src={CDN+"website-assets/blog/1-colorswatch.jpg"}
                  alt={"ColorSwatch"}
                />
              </Image>
              <ImageCopyright>Photo: <a href="https://unsplash.com/it/@alirezadolati" target="_blank" rel="noreferrer">Alireza Dolati</a></ImageCopyright>
            </OzCol>
            <OzCol widthmd={12} order={1}>
              <Date>
                {(() => {switch(lang){
                  default:
                    return "Thursday September 12, 2024";
                }})()}
              </Date>
              <Line/>
              <Title>
                {(() => {switch(lang){                 
                  default:
                    return "ColorSwatch: Generate Image Samples for Color Swatches";
                }})()}
              </Title>
              <Description>
                {(() => {switch(lang){                  
                  default:
                    return <>
                      <p>
                        We are pleased to introduce ColorSwatch: a feature that allows you to generate color or material sample cutouts automatically in few seconds.
                      </p>
                      <h3>
                        How the Process Works:
                      </h3>
                      <p>
                        1. <b>Script configuration:</b><br/>
                        Depending on your needs, we will configure the action and the desired output: the sample size, the number of results and any necessary naming conventions.
                      </p>  
                      <p>
                        2. <b>Image Upload:</b><br/>
                        Upload an image via API or Hotfolders. This automated process can be seamlessly integrated into your existing workflows.
                      </p>
                      <p>
                        3. <b>Image Processing:</b><br/>
                        Ozwol Platform analyses the uploaded image, taking into account all product interferences such as folds, belts, labels, strong shadows, zips and buttons. The automated system rejects areas with too many distractions and selects the cleanest parts of the image, all within seconds.<br/>
                        At the end of the process you will receive the output in line with the given specifications.
                      </p>
                      <p>
                        For more information on how to integrate ColorSwatch into your processes, please feel free to contact us.
                      </p>
                    </>;
                }})()}
              </Description>
              <br/>
              <Link to={"/"+lang+"/contact-us"}>
                <OzButton variant="chetwodeblue">
                  {(() => {switch(lang){
                    // case "it":
                    //   return "ColorSwatch: Generate Image Samples for Color Swatches";
                    default:
                      return "Contact us";
                  }})()}
                </OzButton>
              </Link>
            </OzCol>
          </OzRow>
        </OzContainer>
      </Wrapper>    
      
    </>
  )

}

